import { useRef, useState } from 'react'
import {
  Modal,
  ModalProps,
  Text,
  Stack,
  Button,
  Card,
  Heading,
  Icon,
  IconButton,
  Divider,
  useToast,
  Box,
} from '@thirstycamel/ui'

import { useActions, useStore } from '../../store/hooks'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { MyOffersSingleOfferResponse } from '@thirsty-camel/hump-club/src/modules/offer/offer.object'
import AvailableOffer from '../AvailableOffer'
import { getNiceErrorMessage } from '../../utils/getNiceErrorMessage'

dayjs.extend(relativeTime)

const ModalTAHNotAvailable: React.FC<ModalProps> = ({ isVisible, onClose }) => {
  const initialFocusRef = useRef(null)

  return (
    <Modal
      onClose={onClose}
      isVisible={isVisible}
      initialFocusRef={initialFocusRef}
      heading="Not delivering to your area"
    >
      <Stack pb={4} spacing={5} flexShrink={0}>
        <Text>
          Sorry, unfortunately it looks like Thirsty At Home isn't currently available in your area!
        </Text>

        <Text>Please stay tuned for Thirsty At Home coming to your area soon.</Text>

        <Button
          variantColor="pink"
          onClick={() => {
            onClose()
          }}
          flexShrink={0}
          mt={3}
        >
          Close
        </Button>
      </Stack>
    </Modal>
  )
}

export default ModalTAHNotAvailable
