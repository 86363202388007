import { Action, action, Thunk, thunk } from 'easy-peasy'
import { backend } from '../../utils/backend'
import CategoryGroupEntity from '@ts/core/src/modules/category/categorygroup.entity'
import ProductListEntity from '@ts/core/src/modules/productlist/productlist.entity'
import { Pagination } from 'nestjs-typeorm-paginate'
import { State } from '../../types/state'

export interface NavigationModel {
  categories: CategoryGroupEntity[]
  setCategories: Action<NavigationModel, CategoryGroupEntity[]>
  fetchCategories: Thunk<NavigationModel>

  productLists: ProductListEntity[]
  setProductLists: Action<NavigationModel, ProductListEntity[]>
  fetchProductLists: Thunk<NavigationModel, string>
}
const navigation: NavigationModel = {
  categories: [],
  productLists: [],

  setCategories: action((state, categories) => {
    state.categories = categories
  }),

  setProductLists: action((state, productLists) => {
    state.productLists = productLists
  }),

  fetchCategories: thunk(async actions => {
    const categories = await backend<CategoryGroupEntity[]>('categorygroup')
    actions.setCategories(categories)
  }),

  fetchProductLists: thunk(async (actions, region) => {
    const productLists = await backend<Pagination<ProductListEntity>>('product-lists/available', {
      params: {
        region,
      },
    })
    actions.setProductLists(productLists.items)
  }),
}

export default navigation
