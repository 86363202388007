import { useRef, useState } from 'react'
import {
  Modal,
  ModalProps,
  Text,
  Stack,
  Button,
  Card,
  Heading,
  Icon,
  IconButton,
  Divider,
  useToast,
  Box,
} from '@thirstycamel/ui'

import { useActions, useStore } from '../../store/hooks'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { MyOffersSingleOfferResponse } from '@thirsty-camel/hump-club/src/modules/offer/offer.object'
import AvailableOffer from '../AvailableOffer'
import { getNiceErrorMessage } from '../../utils/getNiceErrorMessage'
import { useRouter } from 'next/router'

dayjs.extend(relativeTime)

const ModalUnderMinOrder: React.FC<
  ModalProps & { backToCart: boolean; minOrderAmount: number }
> = ({ isVisible, onClose, backToCart, minOrderAmount }) => {
  const router = useRouter()
  const initialFocusRef = useRef(null)

  return (
    <Modal
      onClose={onClose}
      isVisible={isVisible}
      initialFocusRef={initialFocusRef}
      heading="Minimum order not met"
    >
      <Stack py={2} spacing={5} flexShrink={0}>
        <Text>Delivery has a minimum spend of ${(minOrderAmount / 100).toFixed(2)}</Text>
        <Text>Please add some extra products to your cart before checking out.</Text>
        <Button
          variantColor="pink"
          onClick={() => {
            onClose()

            if (backToCart) {
              router.push('/cart', '/cart')
            }
          }}
          flexShrink={0}
        >
          {backToCart ? 'Back to cart' : 'Close'}
        </Button>
      </Stack>
    </Modal>
  )
}

export default ModalUnderMinOrder
