import React, { createContext, useContext, useMemo } from 'react'
import useMedia from 'use-media'

export interface MediaQueryContextValue {
  isMobile: boolean
  isTablet: boolean
  isSmallDesktop: boolean
  isMediumDesktop: boolean
  isLargeDesktop: boolean
}

export const MediaQueryContext = createContext<MediaQueryContextValue>(undefined)

export const mediaQueries = {
  isMobile: '(max-width: 30em)',
  isTablet: '(min-width: 30em) and (max-width: 48em)',
  isSmallDesktop: '(min-width: 48em) and (max-width: 62em)',
  isMediumDesktop: '(min-width: 62em) and (max-width: 80em)',
  // isLargeDesktop: '(min-width: 80em)',
}

export function useMediaQueryContext() {
  return useContext(MediaQueryContext)
}

export interface MediaQueryProviderProps {
  children: React.ReactNode
}

export const MediaQueryProvider = ({ children }: MediaQueryProviderProps) => {
  const isMobile = useMedia(mediaQueries.isMobile)
  const _isTablet = useMedia(mediaQueries.isTablet)
  const _isSmallDesktop = useMedia(mediaQueries.isSmallDesktop)
  const _isMediumDesktop = useMedia(mediaQueries.isMediumDesktop)

  const isTablet = _isTablet && !isMobile
  const isSmallDesktop = _isSmallDesktop && !isTablet
  const isMediumDesktop = _isMediumDesktop && !isSmallDesktop

  const isLargeDesktop = !isMobile && !isMobile && !isMobile && !isMediumDesktop

  const value = useMemo(
    () => ({ isMobile, isTablet, isMediumDesktop, isSmallDesktop, isLargeDesktop }),
    [isMobile, isTablet, isMediumDesktop, isSmallDesktop, isLargeDesktop],
  )

  return <MediaQueryContext.Provider value={value}>{children}</MediaQueryContext.Provider>
}

export default MediaQueryProvider
