import { Action, action, computed, Computed } from 'easy-peasy'
import { setCookie } from 'nookies'
import { StoreModel } from '.'

export interface Over18Model {
  _isOver18: boolean
  isOver18: Computed<Over18Model, boolean, StoreModel>
  setOver18: Action<Over18Model, boolean | undefined>
}

const over18: Over18Model = {
  _isOver18: false,
  isOver18: computed(
    [state => state._isOver18, (state, store) => !!store.auth?.user?.id],
    (local, global) => local || global,
  ),
  setOver18: action((state, payload) => {
    if (payload) {
      state._isOver18 = true
      setCookie(null, 'over18', 'true', { path: '/' })
    } else {
      state._isOver18 = false
    }
  }),
}

export default over18
