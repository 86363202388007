import location, { LocationModel } from './location'
import auth, { AuthModel } from './auth'
import store, { StoresModel } from './store'
import modal, { ModalModel } from './modal'
import cart, { CartModel } from './cart'
import sidebar, { SidebarModel } from './sidebar'
import search, { SearchModel } from './search'
import offers, { OffersModel } from './offers'
import navigation, { NavigationModel } from './navigation'
import orders, { OrdersModel } from './orders'
import over18, { Over18Model } from './over18'
import invites, { InviteModel } from './invites'

export interface StoreModel {
  auth: AuthModel
  store: StoresModel
  modal: ModalModel
  cart: CartModel
  sidebar: SidebarModel
  location: LocationModel
  search: SearchModel
  offers: OffersModel
  navigation: NavigationModel
  orders: OrdersModel
  over18: Over18Model
  invites: InviteModel
}

const model: StoreModel = {
  auth,
  cart,
  store,
  modal,
  sidebar,
  location,
  search,
  over18,
  offers,
  navigation,
  orders,
  invites,
}

export default model
