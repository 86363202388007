import React, { useRef } from 'react'
import { Modal, ModalProps } from '@thirstycamel/ui'

import { useStore } from '../../store/hooks'
import StorePicker from '../StorePicker'

export interface ModalStorePickerProps extends ModalProps {}

export const ModalStorePicker = ({ isVisible, onClose }: ModalStorePickerProps) => {
  const initialFocusRef = useRef(null)

  return (
    <Modal onClose={onClose} isVisible={isVisible} m={0} initialFocusRef={initialFocusRef}>
      <StorePicker initialFocusRef={initialFocusRef} />
    </Modal>
  )
}

export default ModalStorePicker
