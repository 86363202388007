import { useRef } from 'react'
import { Modal, ModalProps, Text, Stack, Button } from '@thirstycamel/ui'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

const ModalSpecialEvent: React.FC<ModalProps & { title: string; message: string }> = ({
  isVisible,
  onClose,
  title,
  message,
}) => {
  const initialFocusRef = useRef(null)

  return (
    <Modal
      onClose={onClose}
      isVisible={isVisible}
      initialFocusRef={initialFocusRef}
      heading={title}
    >
      <Stack py={2} spacing={5} flexShrink={0}>
        <Text>{message}</Text>
        <Button width="100%" variantColor="pink" mt={5} onClick={onClose}>
          Close
        </Button>
      </Stack>
    </Modal>
  )
}

export default ModalSpecialEvent
