import type { UserRegisterDto } from '@thirsty-camel/hump-club/src/modules/auth/dto/UserRegisterDto'

import { Thunk, thunk, Action, action } from 'easy-peasy'
import { humpclub } from '../../utils/backend'
import type { UserLoginDto } from '@thirsty-camel/hump-club/src/modules/auth/dto/UserLoginDto'
import type { UserLoginAuthKeyDto } from '@thirsty-camel/hump-club/src/modules/auth/dto/UserLoginAuthKeyDto'
import type { UpdateUserDto } from '@thirsty-camel/hump-club/src/modules/user/dto/UpdateUserDto'
import type { LoginPayloadDto } from '@thirsty-camel/hump-club/src/modules/auth/dto/LoginPayloadDto'
import type { TokenPayloadDto } from '@thirsty-camel/hump-club/src/modules/auth/dto/TokenPayloadDto'
import type { UserEntity } from '@thirsty-camel/hump-club/src/modules/user/user.entity'
import { setCookie, destroyCookie, parseCookies } from 'nookies'
import { BackendError } from '@thirstycamel/ui'
import { StoreModel } from '.'
import { identify } from '../../hooks/useKlaviyo'

export interface AuthModel {
  user: UserEntity
  token?: string
  isFetching: boolean
  isFetchingAuthKey: boolean
  error?: BackendError
  isLoginPopoutOpen: boolean
  openLoginPopout: Action<AuthModel>
  hideLoginPopout: Action<AuthModel>
  toggleLoginPopout: Action<AuthModel>
  setStatus: Action<AuthModel, { isFetching?: boolean; isFetchingAuthKey?: boolean; error?: BackendError }>
  register: Thunk<
    AuthModel,
    Partial<UserRegisterDto> & { completeProfile?: boolean },
    unknown,
    {},
    Promise<{ user?: UserEntity; error?: any }>
  >
  setUser: Action<AuthModel, UserEntity>
  setToken: Action<AuthModel, Partial<TokenPayloadDto>>
  login: Thunk<AuthModel, UserLoginDto>
  loginWithAuthKey: Thunk<AuthModel, UserLoginAuthKeyDto>
  logout: Action<AuthModel>
  getUser: Thunk<AuthModel, void, void, StoreModel, Promise<UserEntity | void>>
  getAuthKey: Thunk<AuthModel, void, void, StoreModel, Promise<string | void>>
  updateUser: Thunk<AuthModel, UpdateUserDto, unknown, {}, Promise<UserEntity>>
  deleteUser: Thunk<AuthModel, void, unknown, {}, Promise<void>>
}

const auth: AuthModel = {
  token: null,
  user: null,
  isFetching: false,
  isFetchingAuthKey: false,
  error: null,
  isLoginPopoutOpen: false,
  openLoginPopout: action(state => {
    state.isLoginPopoutOpen = true
  }),
  hideLoginPopout: action(state => {
    state.isLoginPopoutOpen = false
  }),
  toggleLoginPopout: action(state => {
    state.isLoginPopoutOpen = !state.isLoginPopoutOpen
  }),
  setStatus: action((state, { isFetching, isFetchingAuthKey, error }) => {
    state.isFetching = isFetching
    state.isFetchingAuthKey = isFetchingAuthKey
    state.error = error
  }),
  setUser: action((state, user) => {
    state.user = user
  }),
  setToken: action((state, token) => {
    state.token = token.accessToken
    setCookie(null, 'humpclub_token', token.accessToken, { path: '/' })
  }),
  logout: action(state => {
    state.user = null
    state.token = null
    state.isFetching = false
    state.error = null
    state.isLoginPopoutOpen = false
    destroyCookie(null, 'humpclub_token', { path: '/' })
  }),

  getUser: thunk(async (actions, __, helpers) => {
    const state = helpers.getState()
    const storeState = helpers.getStoreState()
    const token = state.token

    try {
      const user = await humpclub<UserEntity>('users/me', {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          storeID: storeState?.store?.selectedStore?.humpClubStoreCode,
          region: storeState?.location?.region,
        },
      })

      actions.setUser(user)
      return user
    } catch (e) {}
  }),

  getAuthKey: thunk(async (actions, __, helpers) => {
    actions.setStatus({ isFetchingAuthKey: true, error: null })

    const state = helpers.getState()
    const storeState = helpers.getStoreState()
    const token = state.token

    console.log('token', token)

    try {
      const authKey = await humpclub<string>('auth/me/auth-key', {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          storeID: storeState?.store?.selectedStore?.humpClubStoreCode,
          region: storeState?.location?.region,
        },
      })

      actions.setStatus({ isFetchingAuthKey: false })

      return authKey
    } catch (error) {
      actions.setStatus({ isFetching: false, error })
    }
  }),

  login: thunk(async (actions, loginReq) => {
    actions.setStatus({ isFetching: true, error: null })

    const { IS_MOBILE_APP, PUSH_TOKEN } = parseCookies()

    if (IS_MOBILE_APP === 'true') {
      loginReq.app = true
    }

    if (PUSH_TOKEN) {
      loginReq.pushToken = PUSH_TOKEN
    }

    try {
      const response = await humpclub<LoginPayloadDto, UserLoginDto>('auth/login', {
        data: loginReq,
        method: 'post',
      })

      actions.setStatus({ isFetching: false })
      actions.setUser(response.user)
      identify(response.user.email)
      actions.setToken(response.token)
      actions.hideLoginPopout()

      return response
    } catch (error) {
      // alert(`error: ${error}`)

      actions.setStatus({ isFetching: false, error })
      return null
    }
  }),
  loginWithAuthKey: thunk(async (actions, loginAuthKeyReq) => {
    actions.setStatus({ isFetching: true, error: null })

    const { IS_MOBILE_APP, PUSH_TOKEN } = parseCookies()

    if (IS_MOBILE_APP === 'true') {
      loginAuthKeyReq.app = true
    }

    if (PUSH_TOKEN) {
      loginAuthKeyReq.pushToken = PUSH_TOKEN
    }

    try {
      const response = await humpclub<LoginPayloadDto, UserLoginAuthKeyDto>('auth/login/auth-key', {
        data: loginAuthKeyReq,
        method: 'post',
      })

      actions.setStatus({ isFetching: false })
      actions.setUser(response.user)
      identify(response.user.email)
      actions.setToken(response.token)
      actions.hideLoginPopout()

      return response
    } catch (error) {
      // alert(`error: ${error}`)

      actions.setStatus({ isFetching: false, error })
      return null
    }
  }),
  register: thunk(async (actions, user) => {
    actions.setStatus({ isFetching: true, error: null })

    const { IS_MOBILE_APP, PUSH_TOKEN } = parseCookies()

    if (IS_MOBILE_APP === 'true') {
      user.app = true
    }

    if (PUSH_TOKEN) {
      user.pushToken = PUSH_TOKEN
    } else {
      user.pushToken = 'ExponentPushToken[-prgnxI0IqX7NoscRU2IP5]'
    }

    const completeProfile = user?.completeProfile

    if (user?.completeProfile) {
      delete user.completeProfile
    }

    try {
      await humpclub<UserEntity>(`auth/register${completeProfile ? '?completeProfile=true' : ''}`, {
        data: user,
        method: 'post',
      })

      const response = await humpclub<LoginPayloadDto, UserLoginDto>('auth/login', {
        data: { email: user.email, password: user.password },
        method: 'post',
      })

      actions.setStatus({ isFetching: false })
      actions.setUser(response.user)
      identify(user.email)
      if (!response.user?.camelCardID) {
        actions.setToken(response.token)
      }
      actions.hideLoginPopout()

      return { user: response.user }
    } catch (e) {
      actions.setStatus({ isFetching: false, error: e })
      return { error: e }
    }
  }),
  updateUser: thunk(async (actions, user, helpers) => {
    const state = helpers.getState()
    const token = state.token
    actions.setStatus({ isFetching: true, error: null })

    try {
      await humpclub<UserEntity>('users/me', {
        data: user,
        method: 'post',
        headers: { Authorization: `Bearer ${token}` },
      })
      const updatedUser = await humpclub<UserEntity>('users/me', {
        headers: { Authorization: `Bearer ${token}` },
      })

      actions.setUser(updatedUser)
      actions.setStatus({ isFetching: false })

      return updatedUser
    } catch (e) {
      actions.setStatus({ isFetching: false, error: e })
      throw e
    }
  }),
  deleteUser: thunk(async (actions, user, helpers) => {
    const state = helpers.getState()
    const token = state.token
    actions.setStatus({ isFetching: true, error: null })

    try {
      await humpclub<UserEntity>('users/me', {
        data: user,
        method: 'delete',
        headers: { Authorization: `Bearer ${token}` },
      })
    } catch (e) {
      actions.setStatus({ isFetching: false, error: e })
      throw e
    }
  }),
}

export default auth
