import { createStore } from 'easy-peasy'
import logger from 'redux-logger'

import model, { StoreModel } from './model'

export const makeStore = (initialState: StoreModel, { isServer }) => {
  const isDev = process.env.NODE_ENV === 'development'

  const middleware = []

  if (isDev) {
    if (!isServer) {
      middleware.push(logger)
    }
  }

  return createStore(model, { middleware, initialState, devTools: isDev && !isServer })
}
