import { Action, action, thunkOn, ThunkOn, thunk, Thunk, actionOn } from 'easy-peasy'
import { parseCookies, setCookie, destroyCookie } from 'nookies'
import { StoreEntity } from '@ts/core/src/modules/store/store.entity'

import { config } from '../../utils/config'
import { StoreModel } from './index'

export interface StorePopoutOptions {
  isSubtle?: boolean
}

export interface StoresModel {
  selected?: string
  changeStore: Action<StoresModel, StoreEntity>
  selectStore: Thunk<StoresModel, StoreEntity, void, StoreModel>
  onSelectStore: ThunkOn<StoresModel, StoreModel>
  selectedStore: StoreEntity | undefined
  fetchStore: Thunk<StoresModel, StoreEntity['slug']>
  fetchStoreRequest: Action<StoresModel>
  clearSelected: Action<StoresModel>
  fetchStoreFailure: Action<StoresModel, any>
  fetchStoreSuccess: Action<StoresModel, StoreEntity>
  isFetching: boolean
  error: any
  isPopoutOpen: boolean
  isPopoutSubtle: boolean
  openPopout: Action<StoresModel, StorePopoutOptions | void>
  hidePopout: Action<StoresModel>
  togglePopout: Action<StoresModel>
}

const store: StoresModel = {
  changeStore: action((state, store) => {
    state.selected = store.slug
    state.selectedStore = store
  }),
  clearSelected: action(state => {
    state.selected = null
    state.selectedStore = null
  }),
  selectStore: thunk(async (actions, store, { getStoreActions }) => {
    const cartActions = getStoreActions().cart

    if (store?.id) {
      cartActions.changeStore(store.id)
    }

    actions.changeStore(store)
  }),
  onSelectStore: thunkOn(
    store => store.selectStore,
    async (_, target) => {
      setCookie(null, 'selected_store', target.payload.slug, { path: '/' })
    },
  ),
  fetchStore: thunk(async (actions, slug) => {
    actions.fetchStoreRequest()

    try {
      const url = `${config.publicRuntimeConfig.backendRoot}/stores/${slug}`

      const response = await fetch(url)

      let result = await response.json()

      if (response.status >= 400 && response.status < 600) {
        throw new Error(JSON.stringify(response))
      }

      actions.fetchStoreSuccess(result)
    } catch (error) {
      actions.fetchStoreFailure(error)
    }
  }),
  fetchStoreRequest: action(state => {
    state.isFetching = true
    state.error = null
  }),
  fetchStoreFailure: action((state, error) => {
    state.error = error
    state.isFetching = false
  }),
  fetchStoreSuccess: action((state, store) => {
    state.selectedStore = store
    state.selected = store.slug
    state.isFetching = false
  }),
  selected: undefined,
  selectedStore: undefined,
  isFetching: false,
  error: null,
  isPopoutOpen: false,
  isPopoutSubtle: false,
  openPopout: action((state, options) => {
    const { isSubtle = false } = options || {}

    state.isPopoutOpen = true
    state.isPopoutSubtle = isSubtle
  }),
  hidePopout: action(state => {
    state.isPopoutOpen = false
  }),
  togglePopout: action(state => {
    /* Toggle the popout open/closed. */
    state.isPopoutOpen = !state.isPopoutOpen
  }),
}

export default store
