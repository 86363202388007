import { OfferEntity } from '@thirsty-camel/hump-club/src/modules/offer/offer.entity'
import { Thunk, thunk, Action, action } from 'easy-peasy'
import { AuthModel } from './auth'
import { BackendError } from '@thirstycamel/ui'
import { humpclub, backend } from '../../utils/backend'
import { StoreModel } from '.'
import OrderEntity from '@ts/core/src/modules/order/order.entity'

export interface OrdersModel {
  data: OrderEntity[]
  fetch: Thunk<OrdersModel, void, any, StoreModel>
  error?: BackendError
  isFetching: boolean
  setStatus: Action<AuthModel, { isFetching: boolean; error?: BackendError }>
  setOrders: Action<OrdersModel, OrderEntity[]>
}

const offers: OrdersModel = {
  data: [],
  isFetching: false,
  error: null,
  setStatus: action((state, { isFetching, error }) => {
    state.isFetching = isFetching
    state.error = error
  }),
  setOrders: action((state, offers) => {
    state.data = offers
  }),
  fetch: thunk(async (actions, _, helpers) => {
    const state = helpers.getStoreState()
    const token = state.auth.token
    actions.setStatus({ isFetching: true })

    try {
      const offers = await backend<OrderEntity[]>('orders/me', {
        method: 'get',
        headers: { Authorization: `Bearer ${token}` },
      })

      actions.setOrders(offers)
      actions.setStatus({ isFetching: false, error: null })
    } catch (e) {
      actions.setStatus({ isFetching: false, error: e })
    }
  }),
}

export default offers
