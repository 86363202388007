import { useRef } from 'react'
import { Modal, ModalProps, Text, Stack, Button } from '@thirstycamel/ui'

import { useActions } from '../../store/hooks'
import type { StoreEntity } from '@ts/core/src/modules/store/store.entity'

export interface ModalConfirmChangeStoreProps extends ModalProps {
  store: StoreEntity
}

const ModalConfirmChangeStore: React.FC<ModalConfirmChangeStoreProps> = ({
  isVisible,
  onClose,
  store,
}) => {
  const { changeStore } = useActions(actions => actions.store)
  const { fetchProductLists } = useActions(actions => actions.navigation)
  const initialFocusRef = useRef(null)

  return (
    <Modal
      onClose={onClose}
      isVisible={isVisible}
      initialFocusRef={initialFocusRef}
      heading="Change Store"
    >
      <Stack px={5} py={2} spacing={5}>
        <Text>
          Do you really want to select <b>{store.name}</b>?
          <br />
          This will <b>empty your cart</b>.
        </Text>
        <Stack isInline justify="flex-end">
          <Button onClick={onClose} variant="outline" variantColor="pink" ref={initialFocusRef}>
            Cancel
          </Button>
          <Button
            variantColor="pink"
            onClick={() => {
              changeStore(store)
              fetchProductLists(store?.region)
              onClose()
            }}
          >
            Continue
          </Button>
        </Stack>
      </Stack>
    </Modal>
  )
}

export default ModalConfirmChangeStore
