import React from 'react'
import { makeStore } from '../store/store'
import { NextComponentType } from 'next'
import { Store, Action } from 'easy-peasy'

export interface WrappedAppProps {
  initialProps: any // stuff return from getInitialProps
  initialState: any // stuff in the Store state after getInitialProps
  isServer: boolean
}

const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__'

function getOrCreateStore(initialState) {
  const isServer = typeof window === 'undefined'

  // Always make a new store if server, otherwise state is shared between requests
  if (isServer) {
    return makeStore(initialState, { isServer })
  }

  // Create store if unavailable on the client and set it on the window object
  if (!window[__NEXT_REDUX_STORE__]) {
    window[__NEXT_REDUX_STORE__] = makeStore(initialState, { isServer })
  }

  return window[__NEXT_REDUX_STORE__]
}

const withRedux = (App: NextComponentType | any) => {
  return class AppWithRedux extends React.Component {
    store

    static async getInitialProps(appContext) {
      // Get or Create the store with `undefined` as initialState
      // This allows you to set a custom default initialState
      const store = getOrCreateStore({})

      // Provide the store to getInitialProps of pages
      appContext.ctx.store = store

      let appProps = {}
      if (typeof App.getInitialProps === 'function') {
        appProps = await App.getInitialProps(appContext)
      }

      return {
        ...appProps,
        initialState: store.getState(),
      }
    }

    constructor(props) {
      super(props)
      this.store = getOrCreateStore(props.initialState)
    }

    render() {
      return <App {...this.props} store={this.store} />
    }
  }
}

export default withRedux
