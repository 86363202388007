import { Action, action, thunkOn, ThunkOn, thunk, ActionOn, actionOn } from 'easy-peasy'

export interface SearchModel {
  isPopoutVisible: boolean
  setPopoutVisible: Action<SearchModel, boolean>
  openPopout: Action<SearchModel>
  hidePopout: Action<SearchModel>
  togglePopout: Action<SearchModel>
  hidePopoutOnAddToCart: ActionOn<SearchModel>
}

const search: SearchModel = {
  isPopoutVisible: false,
  setPopoutVisible: action((state, payload) => {
    state.isPopoutVisible = payload
  }),
  openPopout: action(state => {
    state.isPopoutVisible = true
  }),
  hidePopout: action(state => {
    state.isPopoutVisible = false
  }),
  togglePopout: action(state => {
    state.isPopoutVisible = !state.isPopoutVisible
  }),
  hidePopoutOnAddToCart: actionOn(
    // @ts-ignore
    (actions, storeActions) => storeActions.cart.addProductToCart,
    state => {
      state.isPopoutVisible = false
    },
  ),
}

export default search
