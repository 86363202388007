import { useRef, useState } from 'react'
import { setCookie } from 'nookies'
import {
  Modal,
  ModalProps,
  Text,
  Stack,
  Button,
  Card,
  Heading,
  Icon,
  IconButton,
  Divider,
  useToast,
  Box,
  SelectInput,
  useForm,
  FormProvider,
} from '@thirstycamel/ui'

import { useActions, useStore } from '../../store/hooks'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { MyOffersSingleOfferResponse } from '@thirsty-camel/hump-club/src/modules/offer/offer.object'
import AvailableOffer from '../AvailableOffer'
import { getNiceErrorMessage } from '../../utils/getNiceErrorMessage'
import { useRouter } from 'next/router'
import Input from '../Input'

dayjs.extend(relativeTime)

const ModalWA: React.FC<ModalProps> = ({ isVisible, onClose }) => {
  const router = useRouter()
  const setRegion = useActions(store => store.location.setRegion)
  const form = useForm()
  const initialFocusRef = useRef(null)

  const handleSubmit = form.handleSubmit(values => {
    onClose()
    setRegion(values.region)
    setCookie(null, 'force_region', values.region, { path: '/' })
  })

  return (
    <Modal
      onClose={onClose}
      isVisible={isVisible}
      hideClose
      initialFocusRef={initialFocusRef}
      heading="Are you looking for WA?"
    >
      <Stack py={2} spacing={5} flexShrink={0}>
        <Button
          variantColor="pink"
          onClick={() => {
            onClose()

            window.location.href = 'https://wa.thirstycamel.com.au'
          }}
          flexShrink={0}
        >
          Yes I'm in WA
        </Button>
        <Divider />
        <Text>Not in WA? Select a state below:</Text>
        <FormProvider {...form}>
          <Box as="form" onSubmit={handleSubmit}>
            <Input
              as={SelectInput}
              name="region"
              label="State"
              isRequired
              placeholder="Select a state..."
              options={[
                {
                  label: 'Victoria',
                  value: 'VIC',
                },
                {
                  label: 'New South Wales',
                  value: 'NSW',
                },
                {
                  label: 'Tasmania',
                  value: 'TAS',
                },
                {
                  label: 'Queensland',
                  value: 'QLD',
                },
                {
                  label: 'Australian Capital Territory',
                  value: 'ACT',
                },
                {
                  label: 'South Australia',
                  value: 'SA',
                },
                {
                  label: 'Northern Territory',
                  value: 'NT',
                },
              ]}
            />
            <Button width="100%" type="submit" variantColor="pink" mt={5}>
              Next
            </Button>
          </Box>
        </FormProvider>
      </Stack>
    </Modal>
  )
}

export default ModalWA
