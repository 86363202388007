import { useRef } from 'react'
import {
  Modal,
  ModalProps,
  Text,
  Stack,
  Button,
  ModalFooter,
  Box,
  Alert,
  TextInput,
  useForm,
  FormProvider,
  useBackendMutation,
  useToast,
} from '@thirstycamel/ui'
import Input from '../Input'
import { InviteUserDTO } from '@thirsty-camel/hump-club/src/modules/invite/invite.object'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import { useActions, useStore } from '../../store/hooks'

export interface ModalHumpClubReferFriendProps extends Omit<ModalProps, 'order'> {
  onClose?: () => void
  onConfirm?: (name: string) => void
}

const InviteUserSchema = yup.object().shape({
  userMobile: yup.string().required('Please enter your phone number').nullable(),
  name: yup.string().required('Please enter the name of your friend').nullable(),
  mobile: yup.string().required('Please enter a valid phone number').nullable(),
})

const ModalHumpClubReferFriend: React.FC<ModalHumpClubReferFriendProps> = ({
  isVisible,
  onClose,
  onConfirm,
}) => {
  const initialFocusRef = useRef(null)

  const { isFetching } = useStore(store => store.invites)
  const { user } = useStore(store => store.auth)
  const { inviteFriend } = useActions(actions => actions.invites)
  const toast = useToast()

  const form = useForm<InviteUserDTO>({
    resolver: yupResolver(InviteUserSchema),
    reValidateMode: 'onBlur',
    defaultValues: {
      userMobile: user?.mobilePhone,
      mobile: null,
      name: null,
    },
  })

  const handleOnSubmit = async (values: InviteUserDTO) => {
    try {
      await inviteFriend(values)
      onClose?.()
      onConfirm?.(values.name)
    } catch (e) {
      toast({
        title: e.message,
        status: 'error',
      })
    }
  }

  return (
    <Modal
      onClose={onClose}
      isVisible={isVisible}
      initialFocusRef={initialFocusRef}
      heading="Refer a friend"
    >
      <Stack spacing={5}>
        <FormProvider {...form}>
          <Box as="form" onSubmit={form.handleSubmit(handleOnSubmit)}>
            <Stack spacing={3}>
              <Text fontWeight="bold">
                Refer a friend and earn a $5 voucher when they make their first transaction!
              </Text>
              {!user && (
                <Input
                  as={TextInput}
                  name="userMobile"
                  label="Your Phone Number"
                  placeholder="e.g. 0450 450 450"
                  isRequired
                />
              )}
              {user && (
                <Input
                  as={TextInput}
                  name="userMobile"
                  placeholder="e.g. 0450 450 450"
                  value={user.mobilePhone}
                  hidden
                />
              )}
              <Input
                as={TextInput}
                name="name"
                label="First name"
                placeholder="e.g. John"
                isRequired
              />
              <Input
                as={TextInput}
                name="mobile"
                label="Phone Number"
                placeholder="e.g. 0421 421 421"
                isRequired
              />
              <Text>
                <i>We will send a text message to this phone number with a link to register.</i>
              </Text>
            </Stack>
          </Box>
        </FormProvider>
        <ModalFooter>
          <Button
            variant="outline"
            variantColor="pink"
            ref={initialFocusRef}
            isRound
            mr={3}
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            variantColor="pink"
            isRound
            isLoading={isFetching}
            onClick={async () => {
              const isValid = await form.trigger()
              if (isValid) {
                handleOnSubmit(form.getValues())
              }
            }}
          >
            Invite
          </Button>
        </ModalFooter>
      </Stack>
    </Modal>
  )
}

export default ModalHumpClubReferFriend
