import { Action, Thunk, action, thunk, ThunkOn, thunkOn } from 'easy-peasy'
import { setCookie, destroyCookie } from 'nookies'

import { config } from '../../utils/config'

export interface LocationModel {
  isFetching: boolean
  error: any
  region: null | string
  address: null | string
  setRegion: Action<LocationModel, null | string>
  setAddress: Action<LocationModel, null | string>
  onSetAddress: ThunkOn<LocationModel>
  fetchRegion: Thunk<LocationModel>
  fetchRegionRequest: Action<LocationModel>
  fetchRegionFailure: Action<LocationModel, any>
  fetchRegionSuccess: Action<LocationModel, string | null>
}

const location: LocationModel = {
  isFetching: false,
  error: null,
  region: 'VIC' /* Default to VIC, so we always have region */,
  address: null,
  setRegion: action((state, region) => {
    state.region = region
  }),
  setAddress: action((state, address) => {
    state.address = address
  }),
  onSetAddress: thunkOn(
    store => store.setAddress,
    async (_, target) => {
      const address = target.payload

      if (address) setCookie(null, 'address', address, { path: '/' })
      else destroyCookie(null, 'address', { path: '/' })
    },
  ),
  fetchRegion: thunk(async (actions, state) => {
    actions.fetchRegionRequest()

    try {
      const URL = `${config.publicRuntimeConfig.backendRoot}/location`
      const resp = await fetch(URL)
      const location = await resp.text()

      if (location) {
        actions.fetchRegionSuccess(location)
      }
    } catch (e) {
      console.warn(e)
      actions.fetchRegionFailure(e)
    }
  }),
  fetchRegionRequest: action(state => {
    state.isFetching = true
    state.error = null
  }),
  fetchRegionFailure: action((state, error) => {
    state.error = error
    state.isFetching = false
  }),
  fetchRegionSuccess: action((state, region) => {
    if (region) {
      state.region = region
    }
    state.isFetching = false
  }),
}

export default location
