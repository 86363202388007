import React, { useEffect, useRef } from 'react'
import {
  Text,
  Stack,
  Button,
  Flex,
  TextInput,
  Icon,
  RemoveScroll,
  Portal,
  Box,
  useTheme,
  PseudoBox,
  AddressObject,
  processAddress,
} from '@thirstycamel/ui'
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete'

/* Ripped from https://stackoverflow.com/questions/61441826/react-highlight-text-between-two-indexes */
const highlightText = (text, matched_substring, start, end) => {
  const highlightTextStart = matched_substring.offset
  const highlightTextEnd = highlightTextStart + matched_substring.length
  const beforeText = text.slice(start, highlightTextStart)
  const highlightedText = text.slice(highlightTextStart, highlightTextEnd)
  const afterText = text.slice(highlightTextEnd, end || text.length)
  return [beforeText, <strong>{highlightedText}</strong>, afterText]
}

interface ModalAddressProps {
  onClose?: () => void
  isVisible?: boolean
  townsOnly?: boolean
  onSelect?: (address: AddressObject) => void
}

function ModalAddress({ onSelect, onClose, townsOnly }: ModalAddressProps) {
  const theme = useTheme()

  const inputRef = useRef(null)

  const {
    value,
    suggestions: { data, loading: isLoading },
    setValue,
  } = usePlacesAutocomplete({
    debounce: 300,
    requestOptions: {
      componentRestrictions: { country: 'au' },
      types: [townsOnly ? '(cities)' : 'address'],
    },
  })

  useEffect(() => {
    inputRef.current?.focus?.()
  }, [])

  const handleSelect = async item => {
    const details = await getDetails(item)
    const address = processAddress(details)

    onSelect && onSelect(address)
  }

  return (
    <Portal>
      <Box
        position="fixed"
        top={0}
        right={0}
        bottom={0}
        left={0}
        bg="blackAlpha.300"
        onClick={onClose}
        zIndex={theme.zIndices.modal - 1}
      />

      <RemoveScroll enabled removeScrollBar={false}>
        <PseudoBox
          zIndex={theme.zIndices.modal}
          role="dialog"
          pos="fixed"
          top={0}
          left={0}
          right={0}
          bottom={0}
          bg="white"
          overflowY="auto"
          pb={12}
        >
          <Stack
            isInline
            align="center"
            p={4}
            borderBottom="1px"
            borderBottomColor="gray.200"
            pos="sticky"
            top={0}
            bg="white"
          >
            <TextInput
              type="search"
              placeholder="Enter delivery address..."
              ref={inputRef}
              value={value}
              onChangeValue={setValue}
            />

            <Button variant="ghost" variantColor="pink" onClick={onClose}>
              Cancel
            </Button>
          </Stack>

          <Stack>
            {data?.length > 0 ? (
              <Box>
                {data.map(item => {
                  const text = item?.structured_formatting?.main_text
                  const matches = item?.structured_formatting?.main_text_matched_substrings
                  const returnText = []

                  if (matches?.length > 0) {
                    matches.forEach((match, index) => {
                      const startOfNext = matches[index + 1]?.offset

                      if (index === 0) {
                        returnText.push(highlightText(text, match, 0, startOfNext))
                      } else {
                        returnText.push(
                          highlightText(text, match, matches[index].offset, startOfNext),
                        )
                      }
                    })
                  }

                  const textElement = returnText.map(text => <>{text}</>)

                  return (
                    <Flex
                      as="li"
                      key={`${item?.place_id}`}
                      px={3}
                      py={2}
                      cursor="pointer"
                      listStyleType="none"
                      borderBottom="1px"
                      borderColor="gray.200"
                      onClick={() => handleSelect(item)}
                    >
                      <Icon name="place" color="pink.500" mt={1} mr={2} />

                      <Stack spacing={0}>
                        <Text fontWeight="medium">{textElement}</Text>
                        <Text color="blackAlpha.600">
                          {item?.structured_formatting?.secondary_text}
                        </Text>
                      </Stack>
                    </Flex>
                  )
                })}
              </Box>
            ) : (
              <Text m={4} color="blackAlpha.600" textAlign="center" fontSize="sm">
                {isLoading
                  ? 'Loading...'
                  : value.length === 0
                  ? 'Search for an address above!'
                  : `No results for '${value}'`}
              </Text>
            )}
          </Stack>
        </PseudoBox>
      </RemoveScroll>
    </Portal>
  )
}

export default ModalAddress
