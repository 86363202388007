export const track = (event, properties) => {
  console.log({ event, properties })
  if (window['_learnq']) {
    //@ts-ignore
    _learnq.push(['track', event, properties])
  }
}

export const identify = email => {
  console.log({ email })

  if (window['_learnq']) {
    //@ts-ignore
    _learnq.push([
      'identify',
      {
        $email: email,
      },
    ])
  }
}
