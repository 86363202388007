import { action, Action } from 'easy-peasy'

export interface SidebarModel {
  isVisible: boolean
  showSidebar: Action<SidebarModel>
  hideSidebar: Action<SidebarModel>
  toggleSidebar: Action<SidebarModel>
}

export const modal: SidebarModel = {
  isVisible: false,
  showSidebar: action(state => {
    state.isVisible = true
  }),
  hideSidebar: action(state => {
    state.isVisible = false
  }),
  toggleSidebar: action(state => {
    state.isVisible = !state.isVisible
  }),
}

export default modal
