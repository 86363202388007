import { action, Action } from 'easy-peasy'
import { MODALS } from '../../components/ModalRoot'

type showModalPayload = string | { name: keyof typeof MODALS; id?: string; props?: any }
type hideModalPayload = string | { name: keyof typeof MODALS; id?: string }

type updateModal = {
  name: string
  id?: string
  props: any
  replace?: boolean
}

export interface ModalModel {
  showing: string[]
  data: {
    [modalId: string]: any
  }
  showModal: Action<ModalModel, showModalPayload>
  hideModal: Action<ModalModel, hideModalPayload>
  hideAllModals: Action<ModalModel>
  updateModal: Action<ModalModel, updateModal>
}

export const modal: ModalModel = {
  showing: [],
  data: {},
  showModal: action((state, payload) => {
    if (typeof payload === 'string') {
      state.showing.push(payload)
      return
    }

    const name = payload.id ? `${payload.name}-${payload.id}` : payload.name

    state.showing.push(name)
    state.data[name] = { ...state[name], props: payload.props }
  }),
  hideModal: action((state, payload) => {
    let name: string

    if (typeof payload === 'string') {
      name = payload
    } else {
      name = payload.id ? `${payload.name}-${payload.id}` : payload.name
    }

    state.showing = state.showing.filter(modalName => modalName !== name)
  }),
  hideAllModals: action(state => {
    state.showing = []
  }),
  updateModal: action((state, { id, name, props, replace }) => {
    const modalName = id ? `${name}-${id}` : name

    state.data[modalName].props = replace ? props : { ...state[modalName].props, ...props }
  }),
}

export default modal
