import { useRef } from 'react'
import { Modal, ModalProps, Text, Stack, Button } from '@thirstycamel/ui'

import { useActions } from '../../store/hooks'
import type { StoreEntity } from '@ts/core/src/modules/store/store.entity'
import { useRouter } from 'next/router'

export interface ModalOver18RequiredProps extends ModalProps {
  store: StoreEntity
}

const ModalOver18Required: React.FC<ModalOver18RequiredProps> = ({ isVisible, onClose }) => {
  const setOver18 = useActions(actions => actions.over18.setOver18)
  const initialFocusRef = useRef(null)
  const router = useRouter()

  const redirectHome = () => {
    router.push('/')
  }

  return (
    <Modal
      onClose={() => {}}
      isVisible={isVisible}
      initialFocusRef={initialFocusRef}
      heading="Over 18?"
      hideClose
    >
      <Stack px={5} py={2} spacing={5}>
        <Stack spacing={2}>
          <Text fontWeight="bold">Are you over 18?</Text>
          <Text>
            Some products cannot be shown to minors. Please confirm you are at least 18 years of
            age.
          </Text>
        </Stack>
        <Stack isInline justify="flex-end">
          <Button
            onClick={redirectHome}
            variant="outline"
            variantColor="pink"
            ref={initialFocusRef}
          >
            Cancel
          </Button>
          <Button
            variantColor="pink"
            onClick={() => {
              onClose()
              setOver18(true)
            }}
          >
            Continue
          </Button>
        </Stack>
      </Stack>
    </Modal>
  )
}

export default ModalOver18Required
