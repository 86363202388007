import { useRef, useState } from 'react'
import {
  Modal,
  ModalProps,
  Text,
  Stack,
  Button,
  Card,
  Heading,
  Icon,
  IconButton,
  Divider,
  useToast,
  Box,
} from '@thirstycamel/ui'

import { useActions, useStore } from '../../store/hooks'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { MyOffersSingleOfferResponse } from '@thirsty-camel/hump-club/src/modules/offer/offer.object'
import AvailableOffer from '../AvailableOffer'
import { getNiceErrorMessage } from '../../utils/getNiceErrorMessage'
import { useRouter } from 'next/router'

dayjs.extend(relativeTime)

const ModalOverMaxOrder: React.FC<ModalProps & { backToCart: boolean }> = ({
  isVisible,
  onClose,
  backToCart,
}) => {
  const router = useRouter()
  const initialFocusRef = useRef(null)

  return (
    <Modal
      onClose={onClose}
      isVisible={isVisible}
      initialFocusRef={initialFocusRef}
      heading="Woah! Your cart is a little heavy"
    >
      <Stack py={2} spacing={5} flexShrink={0}>
        <Text>Unfortunately you have exceed the maximum cart total of $250.</Text>
        <Text>
          Planning a party? Contact your local Thirsty Camel bottleshop to discuss your order. We’re
          here to help.
        </Text>
        <Button
          variantColor="pink"
          onClick={() => {
            onClose()

            if (backToCart) {
              router.push('/cart', '/cart')
            }
          }}
          flexShrink={0}
        >
          {backToCart ? 'Back to cart' : 'Close'}
        </Button>
      </Stack>
    </Modal>
  )
}

export default ModalOverMaxOrder
