import React, { memo, FC } from 'react'
import { useRouteChange } from '@thirstycamel/ui'

import { useActions, useStore } from '../../store/hooks'
import ModalStorePicker from '../ModalStorePicker'
import { ModalConfirmChangeStore } from '../ModalConfirmChangeStore'
import { ModalRedeemOfferOnline } from '../ModalRedeemOfferOnline'
import { ModalRedeemOfferInStore } from '../ModalRedeemOfferInStore'
import { ModalApplyOffer } from '../ModalApplyOffer'
import ModalTAHNotAvailable from '../ModalTAHNotAvailable'
import ModalOverMaxOrder from '../ModalOverMaxOrder'
import ModalUnderMinOrder from '../ModalUnderMinOrder'
import ModalWA from '../ModalWA'
import ModalSA from '../ModalSA'
import ModalSpecialEvent from '../ModalSpecialEvent'
import ModalAddress from '../ModalAddress'
import ModalOver18Required from '../ModalOver18Required/ModalOver18Required'
import ModalHumpClubReferFriend from '../ModalHumpClubReferFriend'

export type Modals = { [key: string]: FC<any> }

export const MODALS = {
  STORE_PICKER: ModalStorePicker,
  CONFIRM_CHANGE_STORE: ModalConfirmChangeStore,
  REDEEM_OFFER_ONLINE: ModalRedeemOfferOnline,
  REDEEM_OFFER_INSTORE: ModalRedeemOfferInStore,
  APPLY_OFFER: ModalApplyOffer,
  TAH_NOT_AVAILABLE: ModalTAHNotAvailable,
  OVER_MAX_ORDER: ModalOverMaxOrder,
  UNDER_MIN_ORDER: ModalUnderMinOrder,
  WA: ModalWA,
  SA: ModalSA,
  SPECIAL_EVENT: ModalSpecialEvent,
  ADDRESS: ModalAddress,
  OVER_18_REQUIRED: ModalOver18Required,
  HUMP_CLUB_REFER_FRIEND: ModalHumpClubReferFriend,
}

const ModalRoot: FC = () => {
  const modals = useStore(state => state.modal)

  const { hideModal, hideAllModals } = useActions(state => state.modal)

  /* Hide all modals on route change. */
  useRouteChange(hideAllModals)

  if (modals.showing.length === 0) return null

  return (
    <>
      {modals.showing.map((modalName, index) => {
        const modalType = modalName.split('-')[0]
        const key = `${modalName}-${index}`
        const Component = MODALS[modalType]

        return (
          <Component
            key={key}
            isVisible
            onClose={() => hideModal(modalName)}
            modalName={modalName}
            {...modals.data?.[modalName]?.props}
          />
        )
      })}
    </>
  )
}

export default memo(ModalRoot)
