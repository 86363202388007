import { InviteEntity } from '@thirsty-camel/hump-club/src/modules/invite/invite.entity'
import { Thunk, thunk, Action, action } from 'easy-peasy'
import { BackendError } from '@thirstycamel/ui'
import { humpclub } from '../../utils/backend'
import { InviteUserDTO } from '@thirsty-camel/hump-club/src/modules/invite/invite.object'
import { StoreModel } from '.'

export interface InviteModel {
  inviteFriend: Thunk<InviteModel, InviteUserDTO, any, StoreModel>
  error?: BackendError
  isFetching: boolean
  setStatus: Action<InviteModel, { isFetching: boolean; error?: BackendError }>
  data: InviteEntity[]
  fetch: Thunk<InviteModel, void, any, StoreModel>
  setInvites: Action<InviteModel, InviteEntity[]>
}

const invites: InviteModel = {
  isFetching: false,
  error: null,
  data: [],
  setStatus: action((state, { isFetching, error }) => {
    state.isFetching = isFetching
    state.error = error
  }),
  inviteFriend: thunk(async (actions, payload, helpers) => {
    const state = helpers.getStoreState()
    const token = state.auth.token
    actions.setStatus({ isFetching: true })

    try {
      const response = await humpclub<{ success: boolean }>('invites', {
        method: 'post',
        headers: { Authorization: `Bearer ${token}` },
        data: payload,
      })
      return response
    } catch (e) {
      actions.setStatus({ isFetching: false, error: null })
      throw new Error(e.response?.data?.message)
    }
  }),
  setInvites: action((state, invites) => {
    state.data = invites
  }),
  fetch: thunk(async (actions, _, helpers) => {
    const state = helpers.getStoreState()
    const token = state.auth.token
    actions.setStatus({ isFetching: true })

    try {
      const invites = await humpclub<InviteEntity[]>('invites', {
        method: 'get',
        headers: { Authorization: `Bearer ${token}` },
      })

      actions.setInvites(invites)
      actions.setStatus({ isFetching: false, error: null })
    } catch (e) {
      actions.setStatus({ isFetching: false, error: e })
    }
  }),
}

export default invites
