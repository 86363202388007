import { useRef } from 'react'
import { Modal, ModalProps, Text, Stack, Button } from '@thirstycamel/ui'

import { useActions } from '../../store/hooks'

const ModalRedeemOfferOnline: React.FC<ModalProps> = ({ isVisible, onClose }) => {
  const { changeStore } = useActions(actions => actions.store)
  const initialFocusRef = useRef(null)

  return (
    <Modal
      onClose={onClose}
      isVisible={isVisible}
      initialFocusRef={initialFocusRef}
      heading="How to redeem this offer"
    >
      <Stack py={2} spacing={5}>
        <Text>
          Redeeming this offer online is easy.
          <br />
          <br />
          Simply make sure you have added the eligible products to the cart in order to meet the
          offer requirements and you'll be prompted to select the offer when you checkout!
        </Text>
        <Stack isInline justify="flex-end">
          <Button
            variantColor="pink"
            onClick={() => {
              onClose()
            }}
          >
            Continue
          </Button>
        </Stack>
      </Stack>
    </Modal>
  )
}

export default ModalRedeemOfferOnline
